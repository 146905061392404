import menuItems from 'navs/menu-items'
import providerMenuItems from 'navs/provider-menu-items'
import studioMenuItems from 'navs/studio-menu-items'

import MainRoutes from 'routes/MainRoutes'
import ProviderRoutes from 'routes/ProviderRoutes'
import StudioRoutes from 'routes/StudioRoutes'

// theme constant
export const gridSpacing = 3
export const drawerWidth = 260
export const smallDrawerWidth = 80
export const appDrawerWidth = 320

export const roleDetails = [
  {
    routes: MainRoutes,
    roles: ['Back_Office'],
    menuItems: menuItems,
  },
  {
    routes: ProviderRoutes,
    roles: ['Provider'],
    menuItems: providerMenuItems,
  },
  {
    routes: StudioRoutes,
    roles: ['Editor', 'Specialist', 'QC', 'Production_Manager'],
    menuItems: studioMenuItems,
  },
]
