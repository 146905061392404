import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import useCurrentUser from 'hooks/useCurrentUser'
import PlaceholderPage from 'views/PlaceholderPage'
import { roleDetails } from 'store/constant'

const AuthorizationController = () => {
  const { currentUser, errors } = useCurrentUser()

  if (errors?.length) {
    return <PlaceholderPage message={errors[0]} />
  }

  if (currentUser) {
    const userRoute = roleDetails.find((route) => currentUser.roles?.some((role) => route.roles.includes(role)))

    if (userRoute) {
      return <RouterProvider router={createBrowserRouter([userRoute.routes])} />
    }

    return <PlaceholderPage message={'You do not have access to this page.'} />
  }

  return <PlaceholderPage message={'Loading...'} />
}

export default AuthorizationController
