import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import ErrorElement from 'routes/ErrorElement'

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/Dashboard')))

// page routing
const Bookings = Loadable(lazy(() => import('views/Bookings')))
const MasterQuery = Loadable(lazy(() => import('views/MasterQuery')))
const Merchandise = Loadable(lazy(() => import('views/Merchandise')))
const MultipleDiary = Loadable(lazy(() => import('views/MultipleDiary')))
const Parts = Loadable(lazy(() => import('views/Parts')))
const Prices = Loadable(lazy(() => import('views/Prices')))
const ProductionDashboard = Loadable(lazy(() => import('views/ProductionDashboard')))
const Products = Loadable(lazy(() => import('views/Products')))
const SendJobs = Loadable(lazy(() => import('views/SendJobs')))
const Workflows = Loadable(lazy(() => import('views/Workflows')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  errorElement: <ErrorElement />,
  children: [
    {
      path: '/',
      element: <Dashboard />,
      errorElement: <ErrorElement />,
    },
    {
      path: 'bookings',
      element: <Bookings />,
    },
    {
      path: 'bookings/send',
      element: <SendJobs />,
    },
    {
      path: 'master-query',
      element: <MasterQuery />,
    },
    {
      path: 'merchandise',
      element: <Merchandise />,
    },
    {
      path: 'multiple-diary',
      element: <MultipleDiary />,
    },
    {
      path: 'parts',
      element: <Parts />,
    },
    {
      path: 'prices',
      element: <Prices />,
    },
    {
      path: 'production-dashboard',
      element: <ProductionDashboard />,
    },

    {
      path: 'products',
      element: <Products />,
    },
    {
      path: 'workflows',
      element: <Workflows />,
    },
  ],
}

export default MainRoutes
