import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import ErrorElement from 'routes/ErrorElement'

// routing
const Dashboard = Loadable(lazy(() => import('views/Dashboard')))
const ProductionDashboard = Loadable(lazy(() => import('views/ProductionDashboard')))

// ==============================|| STUDIO ROUTING ||============================== //

const StudioRoutes = {
  path: '/',
  element: <MainLayout />,
  errorElement: <ErrorElement />,
  children: [
    {
      path: '/',
      element: <Dashboard />,
      errorElement: <ErrorElement />,
    },
    {
      path: 'production-dashboard',
      element: <ProductionDashboard />,
    },
  ],
}

export default StudioRoutes
