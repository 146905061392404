import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'

// ==============================|| STUDIO MENU ITEMS ||============================== //

const studioMenuItems = {
  items: [
    {
      id: 'general',
      title: 'General',
      type: 'group',
      children: [
        {
          id: 'dashboard',
          title: 'Home',
          type: 'item',
          url: '/',
          icon: HomeOutlinedIcon,
          breadcrumbs: false,
        },
        {
          id: 'productionDashboard',
          title: 'Production Dashboard',
          collapsedTitle: 'Prod Board',
          type: 'item',
          url: `/production-dashboard`,
          icon: DashboardOutlinedIcon,
          breadcrumbs: false,
        },
      ],
    },
  ],
}

export default studioMenuItems
